<template>
	<div>
		
		<CRow><CButton size="sm" class="btn btn-link back-btn" 
				@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
			</CRow>
		
		<CCard class="p-4"> 
			<CCardHeader>
				<CRow>
			
					<CCol lg="6">
						<h5>Part / {{dataParams.part_no}}</h5>
					</CCol> 
					<!-- <CCol lg="6">
			      		<div style="text-align: right"> 
			      			<CButton color="btn btn-info">Part Issuance</CButton> &nbsp; 
			      			<CButton color="btn btn-outline-danger" @click="backToTable()">Close</CButton> &nbsp; 
			      		</div>
					</CCol> -->
				</CRow>
			</CCardHeader>
			<CCardBody>
				<CCard class="p-4 pl-5 pr-5 ml-5 mr-5 mt-0"> 
					<CRow class="ml-3 mr-3 mt-2">
						
						<CCol lg="6">
							Part Identifier: <strong>{{dataParams.part_identifier}}</strong>
						</CCol>
						<CCol lg="6">
							Category: <strong>{{dataParams.category.setting_name}}</strong>
						</CCol>  
					</CRow>
					<CRow class="ml-3 mr-3 mt-2"> 
						<CCol lg="6">
							Parts Name: <strong>{{dataParams.part_name}}</strong>
						</CCol>
						<CCol lg="6">
							Quantity: <strong>{{dataParams.main_qty}}</strong>
						</CCol> 
						 
					</CRow>  

					<CRow class="ml-3 mr-3 mt-2">
						
						<CCol lg="6">
							Manufacturer: <strong>{{dataParams.manufacturer && dataParams.manufacturer != "" ? dataParams.manufacturer : "N/A"}}</strong>
						</CCol>
						<CCol lg="6">
							Location: <strong>{{dataParams.part_location ? dataParams.part_location.setting_name : "N/A"}}</strong>
						</CCol>  
					</CRow>

					<CRow class="ml-3 mr-3 mt-2">
						
						<CCol lg="6">
							Description: <strong>{{dataParams.description && dataParams.description != "" ? dataParams.description : "N/A"}}</strong>
						</CCol>
						<CCol lg="6">
							Stock Limit: <strong>{{dataParams.stock_limit ? dataParams.stock_limit : "N/A"}}</strong>
						</CCol>  
					</CRow>
					
					<hr/>

					<CRow class="mb-1">
						<!-- <CCol lg="2"></CCol> -->
						<CCol lg="12">
							<h6>Purchasing</h6>
						</CCol> 
					</CRow>
					<CRow class="mb-1">
		        		<CCol lg="12">
		        			<CDataTable
						      	:items="purchasingList.data"
						      	:fields="[
								  {
										key: 'vendor_id_label', 
										label: 'Vendor'
									},
									{
										key: 'date_purchased_label', 
										label: 'Date Purchased'
									},
									{
										key: 'price_per_unit', 
										label: 'Price/unit'
									},
									{
										key: 'qty', 
										label: 'Quantity'
									},
									
									{
										key: 'total', 
										label: 'Total Price'
									},
									{
										key: 'po', 
										label: 'P.O Number'
									},
									
									{
										key: 'action', 
										label: 'Actions'
									}
								]"
								hover
								striped
								border
								small
								fixed
								
						    >   
						    	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            > 
						                <!-- <CDropdownItem  @click="updatePurchasing(item)">Edit</CDropdownItem> -->
						                <CDropdownItem v-if="config.getPermission('part_inventory').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						                <CDropdownItem  @click="viewHistoryRow(item)"><i class="fa fa-list-alt"> View History </i></CDropdownItem>
						            </CDropdown>
						        </td>
					      	</template>
						    </CDataTable>  
		        		</CCol> 
		        	</CRow>   
		        	<CRow class="mb-1" v-if="config.getPermission('purchasing').create">
		        		<CCol lg="12">
		        			<div> 
			        			<CButton size="sm" color="btn btn-info" @click="toggleModal(); editMode = false;"><i class="fa fa-plus"></i> Add Purchase </CButton>
			        		</div>
		        		</CCol>
		        	</CRow>  
		        	
				</CCard>
			</CCardBody> 
		</CCard>  
		<CModal title="Add Purchasing" :show.sync="formModal" color="info" size="lg">
           	<form @submit.prevent="submit">
           		<CRow > 
					<CCol lg="12">
						<div class="form-group"> 
							<label>Vendor <span class="text-danger">*</span></label>
							<v-select 
								label="vendor_name" 
								:options="vendorList.data"
								:reduce="item => item.id"
								v-model="purchasingParams.vendor_id"
								placeholder="Select vendor" 
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!purchasingParams.vendor_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div>
					</CCol>
					
				</CRow>
				<CRow > 
					<CCol lg="12">
						<label>Quantity <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter quantity"
		                  v-model="purchasingParams.qty"
						  required="true"
						  type="number"
		                />
					</CCol> 
				</CRow>
				<CRow > 
					<CCol lg="6">
						<label>Price per unit <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter price per unit"
		                  v-model="purchasingParams.price_per_unit"
						  required="true"
						  type="number"
		                />
					</CCol> 
					<CCol lg="6">
						<label>Total <span class="text-danger">*</span></label>
						<CInput
		                  placeholder=""
		                  v-model="purchasingParams.qty * purchasingParams.price_per_unit"
						  required="true"
						  type="number"
						  disabled
		                />
					</CCol> 
				</CRow>
				<CRow > 
					<CCol lg="12">
						<label>Date purchased <span class="text-danger">*</span></label>
						<Datepicker 
							input-class="form-control bg-white" 
							v-model="purchasingParams.date_purchased"
							placeholder="Date purchased" 
							:required="true"
						>
						</Datepicker>  
					</CCol>
				</CRow>
				<CRow class="mt-3"> 
					<CCol lg="12">
						<label>PO <span class="text-danger">*</span></label>
						<CInput
		                  placeholder="Enter PO"
		                  v-model="purchasingParams.po"
						  required="true" 
		                />
					</CCol>  
				</CRow>
				
	            <div slot="footer" class="w-100">
					<CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	            	<CButton @click="toggleModal" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right">
	                    Cancel
	                </CButton>
	              
	                
	            </div>
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
        <CModal title="Purchasing History" :show.sync="historyModal" size="lg" color="info">
            
			<CRow class="mb-1">
        		<CCol lg="12">
        			<CDataTable
				      	:items="historyList.data"
				      	:fields="[
						  {
								key: 'vendor_id_label', 
								label: 'Vendor'
							},
							{
								key: 'date_purchased_label', 
								label: 'Date Purchased'
							},
							{
								key: 'qty', 
								label: 'Quantity'
							},
							
							{
								key: 'price_per_unit', 
								label: 'Price per unit'
							},
							{
								key: 'total', 
								label: 'Total price'
							},
							
							
							{
								key: 'po', 
								label: 'PO'
							},
							
							{
								key: 'action', 
								label: 'Actions'
							}
						]"
						hover
						striped
						small
						fixed
						custom
				    >   
				    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            > 
							
				                 <CDropdownItem  @click="updatePurchasing(item)"><i class="fa fa-pencil-square-o">Edit</i></CDropdownItem>
				                <CDropdownItem  @click="deleteRow(item, 'single')"><i class="fa fa-trash">Edit</i></CDropdownItem>
				            </CDropdown>
				        </td>
			      	</template>
				    </CDataTable>  
        		</CCol> 
        	</CRow>   	
				
            <div slot="footer" class="w-100">
            	<!-- <CButton @click="toggleHistoryModal" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right">
                    Close
                </CButton>  -->
                
            </div> 
           	<div slot="footer" class="w-100"></div>
        </CModal>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2'; 
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';   

export default {
	mounted(){  

		this.getData();  
		this.getVendorList();
		this.getPurchasing();

	},
	data(){
		return{    
			dataList: [], 
			purchasingParams: { 
				vendor_id:"",
				part_inventory_id:"",
				qty:0,
				price_per_unit:0,
				total:0,
				date_purchased:"",
				po:"", 
			},
			dataParams: {
				part_identifier:"",
				category_id:"",
				part_name:"",
				manufacturer:"",
				part_location_id:"",
				model:"",
				stock_limit:"",
				unit:"",
				description:"",
				main_qty: 0,
				cost: 0,
				status: "",
				category: {
					setting_name: ""
				}
			},
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			purchasingList: {
				data: []
			},
			historyList: {
				data: []
			},
			
			uploadPercentage: 0,
			config,
			moment,
			formModal: false,
			historyModal: false
		}
	},
	name: 'Tables',
	components: {Datepicker, vSelect},
	methods: { 
	

		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/partInventory/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;   
			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 
		getPurchasing(){

			axios.get(config.api_path+"/purchasing?part_inventory_id="+this.$route.params.id)
			.then(response=>{  
				this.purchasingList = response.data;
				this.purchasingList.data = this.purchasingList.data.map((value, index)=>{
					if(value.date_purchased){
	    				value.date_purchased_label = moment.unix(value.date_purchased).format('MMMM DD, YYYY')
	    			}
	    			else{
	    				value.date_purchased_label = "N/A"
	    			}
	    			if(value.vendor){
	    				value.vendor_id_label = value.vendor.vendor_name;
	    			}
	    			else{
	    				value.vendor_id_label = "N/A"
	    			}
	    			return value;
				});   
			})

		},   

		getVendorList(){

			axios.get(config.api_path+"/vendor?page=1&limit=1000000")
			.then(response=>{  
				this.vendorList = response.data;   
			})

		},   


	    backToTable(){
	    	this.$router.push('/main/parts_inventory')
	    }, 

	    updateModal(item){
	    	this.$router.push('/main/update_booking/'+item.id)
	    },

	    viewDr(item){
	    	this.$router.push('/main/view_booking/'+item.id+"/dr")
	    }, 

		print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('mts_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },
	    toggleModal(){
	    	this.formModal = !this.formModal;
	    },
	    toggleHistoryModal(){
	    	this.historyModal = !this.historyModal;
	    },
	    updatePurchasing(item){
	    	this.selectedRow = item;
	    	this.toggleHistoryModal();
	    	this.toggleModal();
	    	this.editMode = true;
	    	this.purchasingParams = this.selectedRow;
	    	this.purchasingParams.date_purchased = item.date_purchased_label;
	    },
	    submit(){
	    	var data = this.purchasingParams;
	    	data.part_inventory_id = this.$route.params.id;
	    	data.total = this.purchasingParams.qty * this.purchasingParams.price_per_unit;
	    	data.date_purchased = this.purchasingParams.date_purchased = moment(this.purchasingParams.date_purchased).startOf('day').unix()
			
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/purchasing/"+this.selectedRow.id, this.purchasingParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/purchasing", this.purchasingParams)
	    	}
	    	ax.then(response => {
	    		var text = 'Purchase successfully added!';
	    		if(this.editMode){
	    			text = 'Purchase successfully updated!';
	    			this.toggleHistoryModal();
	    			this.toggleModal();
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.purchasingParams = {
					vendor_id:"",
					part_inventory_id:"",
					qty:0,
					price_per_unit:0,
					total:"",
					date_purchased:"",
					po:"", 
				}
				this.getPurchasing();
				this.getData();
	    		this.formModal = false;
	    	})
	    },
	    deleteRow(row, type){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this purchase', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					var params = "";
					if(type == "single"){
						params = "?single=true";
					}
					axios.delete(config.api_path+"/purchasing/"+row.id+params)
			    	.then(response=>{
			    		this.getPurchasing();
			    		if(type == "single"){
			    			this.viewHistoryRow(row);
			    		}
			    		Swal.fire({
							title: 'Success!',
							text: "Purchase successfully deleted!",
							icon: 'success', 
						})
						this.getData();
			    	})
				}
			})  
	    	
	    },
	    viewHistoryRow(item){ 
	    	this.toggleHistoryModal();
			axios.get(config.api_path+"/purchasing?part_inventory_id="+this.$route.params.id+"&vendor_id="+item.vendor_id+"&full=true&page=1&limit=1000000")
			.then(response=>{  
				this.historyList = response.data;
				this.historyList.data = this.historyList.data.map((value, index)=>{
					if(value.date_purchased){
	    				value.date_purchased_label = moment.unix(value.date_purchased).format('MMMM DD, YYYY')
	    			}
	    			else{
	    				value.date_purchased_label = "N/A"
	    			}
	    			if(value.vendor){
	    				value.vendor_id_label = value.vendor.vendor_name;
	    			}
	    			else{
	    				value.vendor_id_label = "N/A"
	    			}
	    			return value;
				});   
			}) 
	    }
 	}
}
</script>
<!-- this.dataParams.delivery_date = this.dataParams.delivery_date = moment(this.dataParams.delivery_date).startOf('day').unix(); -->